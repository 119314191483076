// forms
// -------------------------
.contact {
  textarea {
    text-align: left;
  }

  .help-block {
    color:$brand-primary;
  }
  .text-danger {
    color:$brand-danger;
    ul {
      padding-left:0;

      li {
        font-size: 21px;
        font-family: $font-family-sans-serif;
        margin-bottom:20px;
      }
    }

  }

  .alert {
    font-size: 18px;
    font-family: $font-family-sans-serif;
  }
}