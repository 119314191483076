
/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */

/* Special class on .container surrounding .navbar, used for positioning it into place. */
.navbar-wrapper {
  position: relative;
  z-index: 20;
  top:$padding-large-horizontal*2+3;
  right:-$grid-gutter-width/2;
}

.navbar-wrapper ul {
  list-style: none !important; /* IE FIX */
  font-size: $font-size-base;
  font-size: 17px;
  letter-spacing: .75px;
  text-transform: uppercase;
  font-family: $font-family-sans-serif;
}

.navbar-wrapper ul ul {
  list-style: none !important; /* IE FIX */
  font-size: $font-size-base;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: none;
  font-family: $font-family-sans-serif;
}

.header .nav {
  > li {
    position: relative;
    padding: 0 $nav-link-padding 0 $nav-link-padding;

    &:hover,
    &:focus {
      background-color: $nav-link-hover-bg;
    }

    .main.active::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 7px;
      left: 0;
      background: $brand-primary;
    }
    > a {
      padding: $nav-link-padding 0 $nav-link-padding 0;
      color:$brand-primary;
      &:hover,
      &:focus {
        text-decoration: none;
        color:$brand-primary;


        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 7px;
          left: 0;
          background: $brand-primary;
        }
      }
    }
    .price {
     margin-top:12px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 81%;
        height: 1px;
        top: -6px;
        margin-left:-2px;
        margin-right:2px;
        background: $brand-primary;
      }
    }
  }
}

@media (min-width: 1200px) {
  .navbar {
    float:right;
  }
}

.navbar-nav {
  ul {
    display: none;
  }
  li:last-child {
    padding-right:0;
  }
}

.navbar-right .dropdown-menu {
  right: auto;
  left: 0;
}

.dropdown-menu {
  border: none;
  box-shadow: none!important;
}



