.teaser-page-header {
  position: relative;

  .teaserWrap,
  .teaser-header{
    position: relative;
    z-index:2;

    width:100%;
    text-align: center;

  }

  .teaser-header{
    h1 {
      font-size:36px;
      line-height:40px;
      padding-left:40px;
      padding-right:40px;
      vertical-align: middle;
      color: $invert-color;
    }
  }

  .teaserWrap {
    background: rgba(37, 78, 140, 0.8);
    height: 200px;
    display: inline-table;
    position: absolute;
    left:0;
    bottom: 0;
    padding:0;

    .teaser-inner{
      display: table-cell;
      vertical-align: middle;
    }
  }

  h2{
    font-size:$font-size-h2-start;
    color: $invert-color;
  }

  h2 .small {
    color: $invert-color;
    display:block;
  }

  a  {

      &:hover,
      &:active,
      &:visited{
        text-decoration:none;

      }

      &:hover {

        span{
          -webkit-transition: .25s color ease-in-out;
          transition: .25s color ease-in-out;
          text-decoration:none;

          color:$body-bg !important;
          &::after{
            background-color:$body-bg!important;
            -webkit-transition: .25s background ease-in-out;
            transition: .25s background ease-in-out;
          }
        }


    }
    h2 span {
      &:hover,
      &:active,
      &:visited{
        text-decoration:none;

      }
    }

  }
  .subheadline {
    text-shadow: 1px 0px 4px rgba(54, 88, 153, 0.77);
    margin-top:32px;
    .text-uppercase {
      position:relative;
    }

    .text-uppercase::after {
      content:'';
      position:absolute;
      width:50%;
      height:2px;
      bottom: -$padding-base-horizontal;
      left:25%;
      background:#fff;
    }
  }
}


.teaser-page-header-button {
  .teaser-btn {
    margin-right:70px;
    margin-top: 0;
    position: relative;

    &:nth-child(odd){
      margin-top:13px;
      margin-bottom:20px;
    }

    a {
      background-color:$btn-primary-bg;
      border-color:$btn-primary-border;
      color:$brand-primary;
      width:220px;
      text-align: right;

      box-shadow:2px 4px 6px 1px rgba(0,0,0, 0.24);
      -moz-box-shadow:2px 4px 6px 1px rgba(0,0,0, 0.24);
      -webkit-box-shadow:2px 4px 6px 1px rgba(0, 0, 0, 0.24);


      &:hover,
      &:active {
        background-color:$btn-primary-hover-bg;
        border-color:$btn-primary-hover-border;

        -web-transition: background 0.4s;
      }

      &:active {
        box-shadow:2px 4px 8px 4px rgba(0, 0, 0, 0.24);
        -moz-box-shadow:2px 4px 8px 4px rgba(0, 0, 0, 0.24);
        -webkit-box-shadow:2px 4px 8px 4px rgba(0, 0, 0, 0.24);
      }


      span {

        &:before {
          color:$brand-primary;
        }

      }
    }
  }

  .online-right {
    padding-right: 40px;
    span {
      background-image: url("../images/online.png");
      background-size: cover;
      width:24px;
      height:24px;
      display:inline-block;
      position:absolute;
      right:8px;
      top:5px;
    }
  }

}

.teaser-page-header-small {
  .teaserWrap {
    height: 122px;
  }
}

.teaser-page-header-button {
  .teaser-inner > div {
    vertical-align: middle;
    display: table-cell;
    height:120px;

    .subheadline {
      margin-top:15px;
    }

    .teaser-header h1 {
      vertical-align: middle;
      display: table-cell;
      min-height:120px;
      height:120px;
      padding-top:5px;
    }

    header {
      vertical-align: middle;
      display: inline-table;
    }
  }
}

.detailPage,
.priceList {
  .teaser-header h1 {
    padding-top:6px;
  }
}
