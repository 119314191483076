// Links
a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus/*,
  &:visited*/{
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    @include tab-focus;
  }
}

.btn {
  color: $brand-primary;
  &:hover,
  &:focus,
  &:visited {
    color: $brand-primary;
  }
}

.teaser-btn {
  .btn {
    background-color: $btn-primary-bg;
    border-color: $btn-primary-border;
    color: $brand-primary;

    box-shadow: 2px 2px 7px 0.2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 7px 0.2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 2px 7px 0.2px rgba(0, 0, 0, 0.2);

    &,
    &:active,
    &.active {
      &:focus,
      &.focus {
        outline: inherit;
        outline-offset: 0;
      }
    }

    &:hover {
      background-color: $btn-primary-hover-bg;
      border-color: $btn-primary-hover-border;

      -web-transition: background 0.4s;
    }

    &:active,
    &.active {
      background-color: $btn-primary-hover-bg;
      border-color: $btn-primary-hover-border;
      box-shadow: 6px 6px 10px .2px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 6px 6px 10px .2px rgba(0, 0, 0, 0.18);
      -webkit-box-shadow: 6px 6px 10px .2px rgba(0, 0, 0, 0.18);
    }

    span {

      &:before {
        color: $brand-primary;
      }

    }
  }
}