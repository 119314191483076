/* kontakt */
.facilities {
  clear:left;
  font-size: $font-size-default;
  .container {
    padding-bottom:0;

    .size {
      padding-left:50px;
    }
  }
}
