/* GLOBAL STYLES
-------------------------------------------------- */
html {
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
}

@media screen and (min-width: 768px) {
  html {
    font-size: 120%;
  }
}

body {
  font-size: $font-size-base;
  line-height: 1.5;
  margin: 0;
  height:100%;
  padding-bottom: 0px;
  letter-spacing: 0.3px;
}

.container {
  width:1370px-$grid-gutter-width;
}

.wrap
.contentWrap {
  margin: 0 auto;
}

.contentWrap {
  background-image: url("/../website/static/images/bg.jpg");
  min-height:753px; /* full screen */

  display:block;
  background-repeat: repeat-y;
  background-size: 100%;
  -webkit-background-size:contain;
  -moz-background-size:contain;
  -o-background-size:contain;
  background-size:contain;
  background-position:top;
}

.closingImages .contentWrap {
  background-repeat: no-repeat;
}
.container{
  margin-right: auto;
  margin-left: auto;
  padding: 0 $grid-gutter-width/2 $grid-gutter-width $grid-gutter-width/2;
}
.container .container {
  background-image:none;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

.row {
  margin-left: -$grid-gutter-width;
  margin-right: -$grid-gutter-width;
}

/* CONTENTS */
#content section {
  margin-bottom: 50px;
}

section img {
  max-width: 100%;
}

/* trenner */
.extra-style-header {
  margin: 1rem 0 0 0;
  padding: 0 15px;
  background: #fafafa;
  border: 1px dotted #bfbfbf;
  clear: both;
  color:#bababa;
}

ul, li  {
  list-style-type: none;
}

/* button */
.btn {
  font-family: $font-family-sans-serif;
  letter-spacing: 0.5px;
}

.btn-lg,
.btn-group-lg > .btn {
  font-size:15px;
}

.btn span {
  color: $btn-default-border;
  font-size:$font-size-large;
}

.btn .lnr-chevron-right:before {
  position:relative;
  top: 1px;
  left:7px;
  font-weight:bold;
}

.clear {
  clear:left;
}
