.priceList {
  &.priceList-astrid {
    .seasons {
    }
    th,
    td {
      width:25%;
    }
  }

  &.priceList-artemis {
    .seasons {
    }
    th,
    td {
      width:20%;
    }
  }

  .tab-content {
    .teaser-inner {
      padding-left:7%;
      padding-right:7%;

      td {

        border-bottom:1px solid $body-bg;

      }
    }
  }

  .col-xs-6:nth-child(odd) {
    clear:left;
  }

  .teaser-text-list {
    h3, ul{
      text-align: left;
    }
    li{
      list-style-type: square;
    }
  }

  li a {
    font-size: $font-size-default;
  }

  .tab-content .teaser,
  .teaser-text .teaserWrap,
  .teaser-text .teaserWrap .text-center{
    text-align: left;
  }

  table .unit,
  table .location,
  table .price {
    width:200px;
  }

  table .unit {
    width:250px;
  }

  th, td {
    font-size: $font-size-default;
    padding: 6px 10px 6px 0;
  }

  th {
    border-bottom:1px solid $body-bg;
  }

  .season {
    width:100%;

    th, td {
      text-align:left;
      vertical-align: top;
    }
  }

  .label {
    font-size:100%;
    padding-left:0;
    display:block;
    text-align: left;
    margin-top:20px;
  }

  .addon {
    padding:0 7%;
    margin-right:-12px;
    margin-left:-12px;
    margin-bottom:40px;

  }

  .pimcore_area_headline {
    margin-top:48px;
  }
}

