section.map {
  h4 {
    font-size:42px;
  }

  .teaserWrap {
    padding-top:0;
  }
}

#map,
#map-fullsize{
  height: 430px;
  position: relative;
}

#map.map-teaser,
#map-fullsize.map-teaser {
  height:226px;
}

.maps-frame {
  height: 430px;
}

.kd-tabbed-vert.header-links .kd-tabbutton a {
  color: #757575;
  display: inline-block;
  height: 100%;
  padding: 0 24px;
  width: 100%;
}

.kd-tabbed-vert.header-links .kd-tabbutton {
  padding: 0;
}

.kd-tabbed-vert.header-links .kd-tabbutton.selected a {
  color: #03a9f4;
}

.kd-tabbed-vert.header-links .kd-tabbutton a:focus {
  text-decoration: none;
}

p.top-desc {
  padding: 1em 1em .1em 1em;
}

p.bottom-desc {
  padding: 0em 1em 1em 1em;
}

#minimap {
  width: 100%;
  position:relative;
  top:0;
  left:0;
  height:220px;
}