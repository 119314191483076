.roomtypes {
  .teaserWrap {
    text-align: left;

    .teaser {
      position:relative;

      .text-direction {
        position: absolute;
        background: rgba(241,246,249, .9);
        height:100%;

        .teaser-btn {
          margin-top:20px;
        }
      }
    }
    .text-right  {
      margin-bottom:48px;
      .text-direction {
        right:24px;
      }
    }

    .text-left  {
      margin-bottom:48px;
      .text-direction {
        left:12px;

        .teaser-inner {
          margin-right:-12px;
        }
      }
    }

    h2 {
      text-align: center;
    }

    .img-right .img-direction {
      float: left;
    }
    .img-left .img-direction {
      float: right;
    }

    h2 {
      margin-top:0;
      margin-bottom:50px;
    }
    h3 {
      font-size: $font-size-h4;
      margin-top:18px;
      margin-bottom:20px;
      padding-bottom:14px;
      position:relative;

      &:after{
        content: '';
        position: absolute;
        width: 60%;
        height: 2px;
        bottom: 0;
        left: 20%;
        background: #365899;
      }

      span {
        font-size: $font-size-default;
        display: inline-block;
        width:100%;
        color: rgba(54,88,153, .6)
      }
    }
    p {
      margin:0 0 8px;
    }
  }
}

  .distributorPage {
    section.roomtypes-gallery {
      clear:left;
      vertical-align: middle;

      h2 {
        padding: auto 75px auto 75px;
        line-height: 130%;
        text-align: center;
      }
      .box {
        display: table-cell;
        vertical-align: middle;
        height: 440px;
      }
  }
}

/*  roomtypes-gallery */
.roomtypes-gallery {
  clear:left;
  .teaserWrap {
    text-align: left;
  }

  .container {
    padding-bottom:0;
  }

  .box {
    min-height: 440px;
    padding-left:12px;
    padding-right:12px;
  }
  header {
    padding:0 12px 28px 12px;

    h2 {
      margin-bottom: 18px;
    }
  }

}