/* kontakt */
.contact {
  .teaser {
    text-align: left;
  }
  .telephone {
    clear:left;
    dsiplay:block;
    font-size:$font-size-h4;
    margin-top:10px;

    span {
        font-size:40px;
        position:relative;
        top:6px;
    }

    a {
      color: $brand-primary;
    }
  }

  .teaser-header,
  .teaser-text {
    padding:0 60px;
  }

  h2 {
    margin-bottom:24px;
    font-size: 42px;
  }
}
