@import url(//fonts.googleapis.com/css?family=PT+Sans:400,700|PT+Serif:400,700);


/* import all scss */
@import "generic/_variables";
@import "generic/_mixins";

@import "lib/_bootstrap";
@import "generic/_scaffolding";

@import "base/_footer";
@import "base/_globals";
@import "base/_nav";
@import "base/_nav-tabs";

@import "core/_form";
@import "core/_icons";
@import "core/_lightbox";
@import "core/_links";

@import "lib/owl/owl.carousel";
@import "lib/owl/owl.theme.default";

@import "modules/_teaser";
@import "modules/_map";
@import "modules/_pricelist";
@import "modules/_galerie";
@import "modules/_thumbnail";
@import "modules/_image-headline";

@import "modules/_teaser-usp";
@import "modules/_teaser-distributor";
@import "modules/_teaser-plain";
@import "modules/_teaser-text";
@import "modules/_teaser-details";
@import "modules/_teaser-page-header";
@import "modules/_teaser-roomtypes";
@import "modules/_teaser-bg-image";
@import "modules/_contact";
@import "modules/_facilities";
@import "modules/_amenities";

@import "core/_media";


.imagelightbox-arrow {
  display:none!important;
}




























