
/* CUSTOMIZE THE Tabs
-------------------------------------------------- */
// Tabs
// -------------------------
.nav-tabs > li > a,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border:none;
}

.nav-tabs > li.active {
  font-weight: bold;
}

.nav-tabs > li.active,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color:$brand-primary;
}

.nav-tabs {
  border-bottom: none;
  font-weight: normal;

  >li {
    margin-bottom:0;
  }
}

.tab-content {
  border:none;
}

.container .tab-content {
  padding-left:100px;
  padding-right:100px;
}