/* */
@media screen and (min-width: 768px) {
  .body-fixed-top .header {
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    background-image: url($page-bg);
    background-repeat: repeat-y;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
    background-position:top;
  }
}

/* touchscreen */
.hover ul {
  display:block;
}


@media (min-width: 1400px) {
  .pageHeader .navbar .container {
    padding-right:0;
    padding-left:0;
    width:100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
  body{
    width:100%;
  }

  .container {
    max-width: 100%;
    width: 100%;
  }

  .container.content{
    margin:0;
    max-width: inherit;
    padding-right:30px;
    padding-left:30px;
  }

  header {
    width: 100%;
    max-width: 100%;
  }

  .wrap .contentWrap {
    width: 100%;
    max-width: 100%;
    margin:0;
  }

  .row {
    margin-left: -12px;
    margin-right: -12px;
  }

  .roomtypes .teaserWrap  {
    h3 {
      font-size:30px;
      margin-bottom:12px;
    }
    p {
      margin:0px 0 4px;
      font-size:18px;
    }
    .teaser-btn {
      margin-top:18px;
    }
  }
}



/* Mobile but Desktop Size
================================================== */

@media only screen and (max-width: 1199px){
  body{
    width:100%;
  }
  .container {
    max-width: 100%;
  }

  .container.content{
    margin:0;
    max-width: inherit;
  }

  header {
    width: 100%;
    max-width: 100%;
  }

  .wrap .contentWrap {
    width: 100%;
    max-width: 100%;
    margin:0;
  }

  .row {
    margin-left: -12px;
    margin-right: -12px;
  }

  .teaser-plain {
    .teaser-inner {
      padding-right:40px;
      padding-left:40px;
      h3{
        font-size:30px;
      }
    }
  }

  .navbar-nav li  {
    float:left;
  }

  .teaser-page-header .teaser-header h1 {
    font-size:28px;
  }

/*  .body-fixed-top .content {
    padding-top:46px;
  }*/

  /* teaser */
  .teaserWrap {
    padding: 42px 20px 24px 20px;
  }

  /* contact */
  .contact .teaser-header, .contact .teaser-text {
    padding: 0 20px;
  }

  .contact h2,
  section.map h4{
    font-size: 28px;
  }

  .teaser-plain {
    padding-top:20px;
  }

  .closingImages .contentWrap {
    min-height: 500px;
  }
  .bg {
    height:500px;
  }
  .teaser-page-header-button .teaser-btn {
    margin-right:20px;
  }

}

/* Smaller Then Desktop
================================================== */
@media only screen and (min-width: 960px) and (max-width: 1199px){

  /*  body {
      border: 1px solid orange;
    }*/

/*  .body-fixed-top .content {
    padding-top:0;
  }*/

  h3,
  h2,
  .usp h3, .usp span {
    font-size: 32px;
  }

  .roomtypes .teaserWrap h2 {
    font-size:36px;
  }

  /* header teaser */
  .detailPage .teaser-page-header .teaserWrap {
    height:148px;
  }
  .teaser-page-header-small .teaserWrap {
    height:82px;
  }

  /* teaser usp */
  .usp .teaserWrap .teaser-header {
    padding-top: 40px;
  }

  /* teaser distributor */
  .distributor .teaserWrap .teaser-header {
    padding-top: 16px;
  }
  .teaser .subheadline .text-uppercase {
    margin-bottom:10px;
  }

  .distributor li {
    line-height:28px;
  }

  /* teaser roomtypes-gallery */
  .distributorPage section.roomtypes-gallery {
    .box {
      height:305px;
      h3 {
        margin:0;
      }
    }
  }

  /* roomtypes */
  section.roomtypes {
    .teaserWrap h3 {
      font-size:23px;
      margin:11px 0 14px 0;
      padding-bottom:12px;

      span {
        font-size:16px;
      }
    }

    p {
      font-size:17px;
      line-height: 120%;
    }
    .teaser-btn {
      margin-top:20px;
    }

    .col-md-12 {
      padding:0;
    }

    .teaserWrap .teaser .text-direction {
      right:12px;
    }
  }
}


/* Smaller Then Desktop
================================================== */
@media only screen and /*(max-width: 959px)*/ (max-width: 991px) {
  .logo img {
    width:100%;
    height:100%;
    min-width: 125px;
    min-height: 61px;
  }

  .navbar-nav {
    float:right;
  }
  .navbar-wrapper {
    top:30px;
  }
  .navbar-wrapper ul {
    font-size:16px;
  }


  .teaser-page-header .teaserWrap {
    height:140px;
  }
  .teaser-page-header h2 {
    font-size:30px;
  }
  .teaser-page-header .teaser-header h1 {
    font-size:21px;
    padding-left:0px;
    padding-right:0px;
    line-height: 26px;
    margin-top:0;
  }

  .teaser-page-header .subheadline {
    margin-top:12px;
  }

  .teaser-page-header-button {
    .headerWrap {
      clear: left;
    }

    .teaser-btn {
      margin-right: 40px;
    }
  }

  .btn {
    font-size: 14px;
  }

  .teaser-btn a.btn {
    width:200px;
  }
}


/* Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
/*  .body-fixed-top .content {
    padding-top:0;
  }*/

  h3,
  h2,
  .usp h3, .usp span{
    font-size: 32px;
  }
  p {
    font-size: 18px;
  }

  .roomtypes .teaserWrap h2 {
    font-size:36px;
  }

  /* header teaser */
  .teaser-page-header-button .teaser-inner {

    .text-right {
      margin-top:20px;
    }
    > div {
      height:auto;
      padding-bottom:12px;

      .teaser-header h1 {
        line-height: 1.4;
        height:auto;
        min-height: inherit;
      }
    }
  }

  .detailPage .teaser-page-header-button .teaserWrap {
    height:145px;

    .teaser-inner {

      .col-sm-7 {
        margin-top: 0;
      }

      .text-right {
        margin-top: 14px;
      }
    }
  }

  .teaser-page-header-small .teaserWrap {
    height:70px;
    h1 {
      margin-bottom:0;
    }
  }

  /* teaser usp */
  .usp {

    .teaserWrap {
      padding-right:62px;
      padding-left:62px;

      .teaser-header {
        padding-top: 10px;
      }
    }

  }

  section.distributor {
    .teaserWrap {
      padding-right:50px;
      padding-left:50px;
    }
  }

  /* teaser distributor */
  .distributor .teaserWrap .teaser-header {
    padding-top: 16px;
  }
  .teaser .subheadline .text-uppercase {
    margin-bottom:10px;
  }

  .distributor li {
    line-height:28px;
  }

  /* teaser roomtypes-gallery */
  .distributorPage section.roomtypes-gallery {
    .box {
      height:auto;
      min-height: inherit;
      h3 {
        margin:0 0 40px 0;
      }
    }
  }
  .detailPage section.roomtypes-gallery {
    .box {
      height:auto;
      min-height: inherit;
      p {
        margin:0 0 40px 0;
      }
    }
  }

  /* roomtypes */
  section.roomtypes {

    .teaserWrap {

      padding: 42px 50px 24px 50px;

      h3 {
        margin-top:40px;
        &:after {
          width:50%;
          left:25%;
        }
      }

      .col-md-12 {
        padding:0;
        margin:0;
      }

      .teaser {
        padding:0;

        .text-direction {
          right:0px;
        }
      }
    }
  }

  .teaser-details {
    .col-sm-6 .teaser-inner {
      padding-right:12px;
      padding-left:12px;

    }
    .teaserWrap {

      .teaser-header h2,
      .teaser-text,

      .row div {
        padding-right:24px
      }
    }

  }

  /* preisliste */
  .container .tab-content{
    padding-right:25px;
    padding-left:25px;
  }

  .teaser-text .teaserWrap{
    padding-right:60px;
    padding-left:60px;
  }


  /* footer */
  footer .teaserWrap {
    padding-left: 12px;
    padding-right:12px;
  }

}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  body{
    background:pink;
  }

  .teaser-page-header-button {
    .col-sm-7 {
      margin-top:10px;
    }

    .headerWrap {
      clear: left;
    }

    .col-sm-12 {
      position: absolute;

    }

    .teaser-btn {
      margin-right: 40px;
    }
  }

}

/* Mobile (Portrait & Landscape)
================================================== */
@media only screen and (max-width: 767px) {
  .body-fixed-top .content {
    padding-top:0;
  }

  h3,
  h2,
  .usp h3, .usp span{
    font-size: 28px;
  }
  p {
    font-size: 18px;
  }

  /* header teaser */
  .teaser-page-header-button .teaser-inner {

    .text-right {
      margin-top:20px;
    }
    > div {
      height:auto;
      padding-bottom:12px;

      .teaser-header h1 {
        line-height: 1.4;
        height:auto;
        min-height: inherit;
      }
    }

    .col-xs-12.col-sm-7 {
      width: 58.33333%;
    }
    .text-right {
      position:absolute;
      margin-top:15px;
      .teaser-btn {
        margin-right:20px;
      }
    }
  }

  .teaser-page-header-small .teaserWrap {
    height:70px;
    h1 {
      margin-bottom:0;
    }
  }

  /* teaser usp */
  .usp {

    .teaserWrap {
      padding-right:62px;
      padding-left:62px;

      .teaser-header {
        padding-top: 10px;
      }
    }

  }

  section.distributor {
    .teaserWrap {
      padding-right:50px;
      padding-left:50px;
    }
  }

  /* teaser distributor */
  .distributor .teaserWrap .teaser-header {
    padding-top: 16px;
  }
  .teaser .subheadline .text-uppercase {
    margin-bottom:10px;
  }

  .distributor li {
    line-height:28px;
  }

  /* teaser roomtypes-gallery */
  .distributorPage section.roomtypes-gallery {
    .box {
      height:auto;
      min-height: inherit;
      h3 {
        margin:0 0 40px 0;
      }
    }
  }
  .detailPage section.roomtypes-gallery {
    .box {
      height:auto;
      min-height: inherit;
      p {
        margin:0 0 40px 0;
      }
    }
  }

  /* roomtypes */
  section.roomtypes {

    .teaserWrap {

      padding: 42px 50px 24px 50px;

      h2 {
        font-size:36px;
        margin-bottom:40px;
      }

      h3 {
        margin-top:5%;
        &:after {
          width:50%;
          left:25%;
        }
      }

      .col-md-12 {
        padding:0;
        margin:0;
      }

      .teaser {
        padding:0;

        .text-direction {
          right:0px;
        }
      }
    }
  }

  .teaser-details {
    .col-xs-12.col-sm-6 {
      width:50%;
      .teaser-inner {
        padding-right:12px;
        padding-left:12px;
      }

    }
    .teaserWrap {

      .teaser-header h2,
      .teaser-text,

      .row div {
        padding-right:24px
      }
    }

  }

  /* preisliste */
  .container .tab-content{
    padding-right:25px;
    padding-left:25px;
  }

  /* preisliste */
  .priceList {
    .teaser-text .teaserWrap {
      padding-right:40px;
      padding-left:40px;
    }

    .tab-content .teaser-inner {
      padding-right:0;
      padding-left:0;
    }
  }



  /* footer */
  footer .teaserWrap {
    padding-left: 12px;
    padding-right:12px;

    .col-xs-12.col-sm-4 {
      width:50%;
    }
  }
}

@media only screen and (max-width: 740px) {
  /* roomtypes */
  section.roomtypes {

    .teaserWrap {

      h2 {
        font-size: 34px;
        margin-bottom: 40px;
      }

      h3 {
        margin-top:4%;
        margin-bottom: 15px;
        font-size: 28px;
      }
      p {
        margin-bottom: 0;
      }
      .teaser-btn {
        margin-top: 18px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  /* header teaser */
  .teaser-page-header .teaserWrap {

  }

  .teaser-page-header {

    .teaserWrap {
      position: inherit;
      margin-top:0;
      margin-bottom:20px;
      background: rgba(37, 78, 140, 1);
    }

    .teaser-pic {
      overflow:hidden;
    }
    .img-responsive {

    }

  }

  .teaser-page-header-button .teaser-inner {
    .text-right {
      margin-top:20px;
    }
    > div {
      height:auto;
      padding-bottom:12px;

      .teaser-header h1 {
        line-height: 1.4;
        height:auto;
        min-height: inherit;
      }
    }

    .col-xs-12.col-sm-7 {
      width: 100%;
    }
    .text-right {
      position:inherit;
      margin-top:15px;
      margin-bottom:10px;
      text-align: center;

      .teaser-btn {
        margin-right:auto;

      }
    }
  }

  /* roomtypes */
  section.roomtypes {
    .teaserWrap {
      .teaser .text-direction {
        position:inherit;
      }

      .teaser-btn {
        margin-bottom:20px;
      }
    }
  }

  .priceList {
    .teaser {
      td, th {
        font-size: 18px;
      }
    }
    .col-xs-6 {
      width:100%
    }
  }

  .teaser-text .teaser-txt p,
  .teaser-text .teaser-txt li,
  .priceList li a{
    font-size:18px;
  }

  .teaser-text .teaserWrap {
    padding-right:40px;
    padding-left:40px;
  }

  .teaser-plain .teaser-btn .btn{
    margin-bottom:20px;
  }
}

/* Mobile (Landscape)
================================================== */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  body {
    /*background:aqua;*/
  }

}

/* Mobile (Portrait only)
================================================== */
@media only screen and (max-width: 479px) {
  body {
    /* background:purple;*/
  }
  .closingImages .contentWrap {
    min-height: 260px;
  }
  .bg {
    height:260px;
  }

}


/* nav */
@media (max-width: 767px) {
  .navbar-toggle {

    margin-right:-12px;

    .icon-bar {
      background-color: $navbar-default-toggle-icon-bar-bg;
    }
  }

  .navbar-wrapper {
    width:100%;
    right:inherit;
    padding:0;
  }

  .navbar-nav {
    margin:0;
    width:100%;
    float: inherit;

    li {
      width:100%;

      display:block;
      clear:left;

      border-top:1px solid #D6D6D6;
    }
  }

  #nav-collapse.collapse {
    .nav {
      display: none;
    }
  }

  .nav li a .icon{
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: calc(100% - 12px);
    margin: 6px 0;
    border-left: 1px solid #D6D6D6;
  }

  .second-level a {
    position:relative;
  }

  .nav li a .icon::before{
    content: "\e873";
    color: $brand-primary;
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    top: calc(50% - 6px);
    width: 60px;
    text-align: center;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .second-level a::before,
  .first-level a.noChild::before,
  .active a.noChild::before,
  body .header .nav > li .price:before{
    font-family: 'Linearicons-Free';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e873";
    color: $brand-primary;
    font-weight: 700;
    /*font-size: 20px;*/
    position: absolute;
    top: calc(50% - 6px);
    width: 60px;
    text-align: center;
    display:block;
    right:0;

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .header .nav > li .main.active::after {
    bottom:0;
  }

  body .header .nav > li .price:before {
    height: inherit;
    margin-left: 0;
    margin-right: 0;
    background: none;
  }

  .dropdown-menu > li > a,
  .header .nav > li .price{
    padding-top:10px;
    padding-bottom:10px;
    margin-top:0;
  }

  .header .nav > li > a:hover:after,
  .header .nav > li > a:focus:after,
  .dropdown  a.mainactive:after,
  .hover  a.mainactive:after,
  .hover  a.main:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: $brand-primary;
  }

  .hover  a.mainactive:after,
  .hover  a.main:after,
  .hover  a.main:focus:after,
  .hover  a.main:hover:after{
    background: #D6D6D6;
    height:1px;
  }

  .dropdown-menu{
    display:none;
    visibility: hidden;
    position:inherit;

    padding:0;
  }

  .hover .dropdown-menu{
    display:block;
    visibility: visible;
    position:inherit;
  }

  .header {
    .container {
      padding-bottom:0;

      .col-xs-4 {
        position:relative;
        z-index: 1000;
      }

      .col-xs-12 {
        margin-top:-80px;
      }
    }

    .navbar-collapse {
      padding:0;
      ul {
        width:100%;

        > li {
          width:100%;
          padding:0;
        }
      }
    }
  }

  #nav-collapse.collapse.in {
    .nav {
      display: block;
      width:100%;
    }
  }

  .navbar-collapse.in {
    overflow: inherit;
  }
  .teaserWrap,
  .distributor .teaserWrap .teaser-header,
  .usp .teaserWrap .teaser-header {
    padding-top: 24px;
  }
}

@media all and (max-width: 575px) {
  .teaser-page-header {
    .col-xs-4 {
      .teaser-header {
        margin-top:20px;
      }
    }
  }

  .start-page {

    .teaser-inner {
      padding-bottom:48px;
    }

    .col-xs-4:nth-child(2n+1) {
      width:50%;
    }
    .col-xs-4:nth-child(2n+2) {

      width:100%;
      position: absolute;
      bottom:-0;
    }

  }

  .teaser-text .teaserWrap {
    padding-right:20px;
    padding-left:20px;
  }

  .teaser-plain .teaser-btn .btn{
    margin-bottom:20px;
  }

  .amenities .col-xs-6 {
    width:100%;

  }
  footer .teaserWrap .col-xs-12.col-sm-4 {
    width:100%;
  }

  #minimap {
    margin-top:20px;
  }
}



@media all and (max-width: 375px) {


  .teaser-page-header .subheadline {
    margin-top:24px;
  }

  .start-page {

    .teaser-inner {
      padding-bottom:100px;
    }
  }

  .amenities .col-xs-6,
  .teaser-details .col-xs-12.col-sm-6{
    width:100%;

  }

  .priceList .teaser-text .teaserWrap {
    padding-right: 20px;
    padding-left: 20px;
  }

  .container .tab-content {
    padding-right: 5px;
    padding-left: 5px;
  }

  .priceList .teaser-text-list ul {
    padding-left:23px;
  }
  .roomtypes-gallery .teaserWrap,
  .contact .teaser-text,
  .amenities .teaserWrap,
  .teaser-details .teaserWrap{
    padding-right: 0;
    padding-left: 0;
  }

  .usp .teaserWrap,
  section.distributor .teaserWrap{
    padding-right: 20px;
    padding-left: 20px;
  }

  section.roomtypes .teaserWrap {
    padding-right: 34px;
    padding-left: 34px;
  }

  .teaser-details .teaserWrap .row div {
    padding-left: 24px;
    padding-right: 24px;
  }

  .contact .teaserWrap,
  .dataprotection .teaserWrap,
  .imprint .teaserWrap{
    padding-left: 12px;
    padding-right: 12px;
  }

  .contact .teaser-header,
  .imprint-content .container{
    padding-right: 0;
    padding-left: 0;
  }
}