footer {
  color:$invert-color;
  font-family: $font-family-sans-serif;
  background-color: $brand-primary;
  width:100%;

  .teaserWrap {
    padding: $grid-gutter-width*2.5 $grid-gutter-width*2.5 $grid-gutter-width*1.8 $grid-gutter-width*2.5;
    background-color: $brand-primary;
    font-size: $font-size-base * 1.125;
  }

  .name {
    font-weight:bold;
    margin-bottom: $grid-gutter-width;
    display:block;
  }

  .fax {
    display:block;
    margin-bottom: $grid-gutter-width;
  }

  a {
    color:$link-color-footer;
    clear: left;
     position:relative;

     &:hover,
     &:focus,
     &:visited {
       color: $link-color-footer;
     }

     &:hover,
     &:focus {
       &:after {
         width: 100%;
         height: 1px;
         background-color: $link-color-footer;
         content: '';
         position: absolute;
         bottom: -2px;
         left: 0;
       }
     }
  }

  .col-md-12 {
    margin-top:20px;
  }

  .area-map a:after {
    display:none;
  }
}

