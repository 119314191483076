.teaser-details {
  .teaserWrap {
    .teaser-header {
      padding-top:$grid-gutter-width*3;
    }

    .teaser {
      text-align: left;
      .imgVertical {
        margin-bottom:$grid-gutter-width/2;
      }
    }

    .row {
      margin-right: -$grid-gutter-width/2;
      margin-left: -$grid-gutter-width/2;
    }
    .row div {
      padding-right: $grid-gutter-width/4;
      padding-left: $grid-gutter-width/4;
    }
    .imgUspVertical {
      margin-bottom: $grid-gutter-width/2;
    }
    @media screen and (min-width: 1400px) {
      img {
        height: auto;
        width: auto;
        max-width: inherit;
      }
    }
  }

  h2{
    font-size: $font-size-h4;
    margin-top: -$grid-gutter-width/4;
  }
  .subheadline {
    font-size:$font-size-h2-start;
    font-family: $font-family-serif;
    vertical-align: middle;

    .small{
      font-size:$font-size-base * 1.125;
      display:block;
    }
  }

  .pull-right {
    .teaser-header h2,
    .teaser-text {
      padding-right:0;
      padding-left:100px;
    }
  }

  .teaserWrap {
    .teaser-header {
      padding-top:0;
    }
    .teaser-header h2,
    .teaser-text {
      padding-right:100px;
    }
  }

  .container {
    padding-bottom:0;
  }
}


