.teaser-text {
  h3 {
    margin-bottom:24px;
  }
  .teaserWrap {
    padding-right:150px ;
    padding-left:150px;
  }
  .teaser-txt p,
  .teaser-txt li{
     line-height:1.75em;
     font-size:$font-size-default;
   }
  .teaser-txt li{
    padding-bottom:10px;
  }
}

