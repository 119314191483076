
//
// Scaffolding
// --------------------------------------------------


// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
  @include box-sizing(border-box);
}
*:before,
*:after {
  @include box-sizing(border-box);
}


body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  margin:0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0 1px rgba(0,0,0,.1);
  
}
body.body-fixed-top {
   @media (max-device-width: $grid-float-breakpoint-max) {
      padding-top: 0;
    }
}

.body-fixed-top .content {
  padding-top: 122px;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: $font-family-sans-serif;
  font-size: inherit;
  line-height: inherit;
}


// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
  margin: 0;
}


// Images

img {
  vertical-align: middle;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
  //@include img-responsive;
}

// Rounded corners
.img-rounded {
  border-radius: $border-radius-large;
}

// Image thumbnails
//
// Heads up! This is mixin-ed into thumbnails.less for `.thumbnail`.
.img-thumbnail {
  padding: $thumbnail-padding;
  line-height: $line-height-base;
  background-color: $thumbnail-bg;
  border: 1px solid $thumbnail-border;
  border-radius: $thumbnail-border-radius;
  //@include transition(all .2s ease-in-out);

  // Keep them at most 100% wide
  //@include img-responsive(inline-block);
}

// Perfect circle
.img-circle {
  border-radius: 50%; // set radius in percents
}


// Horizontal rules

hr {
  margin-top:    $line-height-computed;
  margin-bottom: $line-height-computed;
  border: 0;
  border-top: 1px solid $hr-border;
}


.area-wysiwyg ol {
  line-height: 140%;
  list-style-position: outside;
  padding-left:0;
  font-size:$font-size-default;

  li {
    list-style-type: decimal;
    margin-bottom:24px;
  }
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.js-hide {
    @extend .sr-only
}
.js-show {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}



 #toggleBox {
   overflow:hidden;
   cursor: pointer;

   &:after {
     font-family: 'Linearicons-Free';
     line-height: 1;
     height:20px;
     width:30px;
     display:block;
     position:absolute;
     bottom:-30px;
     left: 50%;
     font-size:26px;
     font-weight:bold;
   }
 }

#toggleBox.up:after {
  content: "\e873";
}
#toggleBox.down:after {
  content: "\e874";
}

.closingImages .contentWrap {
  background-size: cover;
}

.setIndexUp {
  position:relative;
  z-index:10;
}

.imprint div {
  font-size: $font-size-large;
}