.teaser-plain {

  padding-top:120px;
  clear: left;
  .teaser-header {
    text-align: center;
  }

  .teaser-inner {
    padding-right:150px ;
    padding-left:150px;
  }
  h3 {
    font-family: $font-family-serif;
    font-size: $font-size-base * 2.625;
    margin-top: 70px;
    width:80%;
    display:inline-block;
  }
  p {
    font-size: $font-size-large;
    padding:0 0 20px 0;
  }
  .teaser-btn {
    .btn {
        margin:0 13px;
    }
  }
}

