.teaser {
  position: relative;
  text-align: center;
  margin-bottom: $base-vertical;

  h2, .h2, h3, .h3 {
    line-height:$line-height-base;
  }
  img {
    width: 100%;
    height: auto;
  }

  .teaser-pic {
    overflow: hidden;
    img {
      @extend .img-responsive;
    }
  }
  .teaser-label {
    position: absolute;
    top:$grid-gutter-width*0.5;
    left: $grid-gutter-width*1.05;
    padding: $base-vertical/3 $grid-gutter-width/3;
    background-color: $gray-base;
    color: $invert-color;
  }
  .teaser-txt a {
    text-decoration: underline;
  }

  .subheadline {
    .text-uppercase {
      position:relative;
      top:-$padding-small-horizontal;
      margin-bottom: $padding-base-horizontal*2.5;
      display:inline-block;
    }

    .small{
      font-size:$font-size-large;
      line-height: $line-height-computed;
      display:block;
    }

    .text-uppercase::after {
      content:'';
      position:absolute;
      width:50%;
      height:2px;
      bottom: -$padding-base-horizontal;
      left:25%;
      background:$brand-primary;
    }
    a {
      .small {
        font-size:$font-size-h2-start-small;
      }

      .text-uppercase {
        top:0;
      }

      .text-uppercase::after {
        background:$invert-color;
        bottom: -$padding-base-horizontal*2;
      }
    }
  }

  /* todo sortieren */
  p{
    font-size:$font-size-default;
  }

  p a,
  .teaser-txt a {
    text-decoration:none;
    color:$link-color;
    position:relative;
  }

  p a {
    text-decoration: none;

    &:hover,
    &:focus {

      color:$link-color;
      &:after {
        width: 100%;
        height: 1px;
        background-color: $link-color;
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
  }

  li a {
    &:hover,
    &:focus {
      border-bottom:1px solid $link-color;
      color:$link-color;
    }
  }
}

.teaserWrap {
  @include wrap-bg;
}

.js-slider-3 {
  .teaser {
    padding: 0 ($grid-gutter-width / 2);
  }
}
.js-slider-onepager {
  .teaser {
    padding: 0 ($grid-gutter-width / 2);
  }
}

.slider-group {
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;

  .teaser {
    margin-bottom: 0;
    background-color: grey;
  }
  .owl-controls {
    text-align: center;
  }
  .owl-pagination,
  .owl-buttons,
  .owl-buttons div {
    display: inline
  }
}

.flexbox {
  .row.flex {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.flexbox {
  .row.flex {
    .teaser {
      padding: 0 0 $base-vertical/2 0;
      background-repeat: repeat-y;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-position: top;
    }
    .teaser-inner {
      padding: 0 $grid-gutter-width/2;
    }
    .slider-group {
      padding-left: 0;
    }
  }
}

/* ~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~ */
/* screen 480px */
/* ~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~ */
@media (max-width: 480px) {
  .flexbox {
    .row.flex {
      .teaser {
        border:none;
        margin: 0 $grid-gutter-width $base-vertical/2 $grid-gutter-width;
        background-color: #fff;
        background-image: none;
      }
    }
  }
}

/* ~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~ */
/* screen 768px */
/* ~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~ */
@media screen and (min-width: 768px) {
  .flexbox {
    .row.flex {
      .teaser {
        border:none;
        margin: 0 $grid-gutter-width $base-vertical/2 $grid-gutter-width;
        background-color: #fff;
        background-image: none;
      }
    }
  }
}

/* ~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~ */
/* screen 992px */
/* ~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~ */
@media screen and (min-width: 992px) {
  .flexbox {
    .row.flex {
      .teaser {
        margin:0;
      }
    }
  }
}
/* ~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~ */
/* screen 1200px */
/* ~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~+~ */
@media screen and (min-width: 1200px) {
  .flexbox {
    .row.flex {
      .teaser {
        margin:0;
        background-color: $teaser-flex-bg-color;
        background-image: url($teaser-flex-bg);
        border-left: $grid-gutter-width solid $teaser-flex-bg-color;
        border-right: $grid-gutter-width solid $teaser-flex-bg-color;
      }
     }
  }

}

/* links */
.float-teaser-link .pimcore_block_plus:after {
  content: 'Anzahl der Links';
  position:relative;
  font-size:14px;
}

.float-teaser-link .pimcore_block_buttons .pimcore_block_plus:after {
  content: '';
}

.float-teaser-link .pimcore_block_buttons .pimcore_block_down:after {
  content: 'Anzahl Links';
  position:relative;
  font-size:14px;
}

.teaser .teaser-btn .sec {
  clear:left;
}

.teaser-btn {
  margin-top: $padding-base-horizontal*2.5;
}