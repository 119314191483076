.distributor {
  .teaserWrap {
    .teaser-header {
      padding-top:$grid-gutter-width*2;
    }
  }

  figure {
    width:100%;
    padding:0 ($grid-gutter-width/2) *1.5 0 ($grid-gutter-width/2) *1.5;
  }

  h2,
  li {
    margin-top: $grid-gutter-width/2;
    font-size:$font-size-default;
  }
  li {
    line-height: $font-size-base * 2.25;
  }

  .subheadline {
    font-size:$font-size-h2-start;
    font-family: $font-family-serif;
    vertical-align: middle;
  }
}


