.usp {
  .teaserWrap {
    .row {
      margin-right: -$grid-gutter-width/2;
      margin-left: -$grid-gutter-width/2;
    }
    .row div {
      padding-right: $grid-gutter-width/4;
      padding-left: $grid-gutter-width/4;
    }
    .imgUspVertical {
      margin-bottom: $grid-gutter-width/2;
    }
    @media screen and (min-width: 1400px) {
      img {
        height: auto;
        width: auto;
        max-width: inherit;
      }
    }
    .teaser-header {
      padding-top:$grid-gutter-width*3;
    }
  }

  h3,
  span {
    font-family: $font-family-serif;
    font-size: $font-size-base * 2.25;
    margin-top: $grid-gutter-width/2;
  }
  span {
    line-height: $font-size-base * 2.25;
  }
}


