// Wrapper for the slide container and indicators
.fullsize-images-teaser {
  position: relative;
  left:15px;
}

.fullsize-images-teaser-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

// Optional captions
// -----------------------------
// Hidden by default for smaller viewports
.fullsize-images-teaser-content {
  position: absolute;
  left: 15%;
  right: 15%;
  top: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;
  text-shadow: $carousel-text-shadow;
}

// Scale up controls for tablets and up
@media screen and (min-width: $screen-sm-min) {

  // Show and left align the captions
  .fullsize-images-teaser-content {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }
}

